import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Verify from "../EmailVerfiy";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import "./index.css";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Loader from "../Loader";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import Checkbox from "@mui/material/Checkbox";
import { productUpload } from "../../Service/services";
const Scan: React.FunctionComponent = () => {
  const [isInput, setIsInput] = useState<any>(true);
  const [isDropdown, setIsDropDown] = useState<any>(false);
  // const [isData, setIsDataId] = useState<any>([]);
  const [isArchive, setIsArchive] = useState<any>([]);
  const [isShowArchive, setIsShowArchive] = useState<any>([]);
  const [isUpdateRows, setIsUpdateRows] = useState<any>([]);
  const [isArchiveShow, setIsArchiveShow] = useState<any>(false);
  const dropdownRef = useRef<any>(null);
  const [selectionModel, setSelectionModel] = useState<any>([]);
  const navigate = useNavigate();
  const [isData, setData] = useState<any>([]);
  const [isresponse, setIsResponse] = useState<any>([]);
  const [isRow, setIsRow] = useState<any>([]);
  const [isColumns, setIsColumns] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<any>(true);
  const [isTableLoading, setIsTableLoading] = useState<any>(false);
  const [isPerPage, setIsPerPage] = useState<any>(10);
  const [isCurrentPage, setIsCurrentPage] = useState<any>(1);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick, true);
    getProductUpload();
  }, []);
  const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const getProductUpload = async () => {
    const request = {
      page: isCurrentPage,
      perPage: isPerPage,
    };
    try {
      const response = await productUpload(request);
      if (response.status === 200) {
        setIsResponse(response.data);
        setData(response.data.data);
        setIsLoading(false);
        setIsTableLoading(false);
      }
    } catch (error: any) {
      console.error(error);
    }
  };
  const handleDocumentClick = (event: any) => {
    if (!dropdownRef.current?.contains(event.target)) {
      setIsDropDown(false);
    }
  };
  const dropdownHandler = () => {
    setIsDropDown(!isDropdown);
  };
  const label = "Show Archived";
  // const rows = [
  //   {
  //     id: 1,
  //     filename: "product-scan.xls",
  //     linecount: 5,
  //     date: "04-12-2023",
  //     idtype: "ASIN",
  //     marketplace: "CA",
  //     condition: "New",
  //     progress: "Completed",
  //   },
  //   {
  //     id: 2,
  //     filename: "product-scan.csv",
  //     linecount: 8,
  //     date: "05-12-2023",
  //     idtype: "UPC",
  //     marketplace: "US",
  //     condition: "Used",
  //     progress: "In-Progress",
  //   },
  //   {
  //     id: 3,
  //     filename: "product-scan.xls",
  //     linecount: 10,
  //     date: "06-12-2023",
  //     idtype: "EAN",
  //     marketplace: "UK",
  //     condition: "New",
  //     progress: "Completed",
  //   },
  //   {
  //     id: 4,
  //     filename: "product-scan.xls",
  //     linecount: 15,
  //     date: "19-12-2023",
  //     idtype: "ASIN",
  //     marketplace: "CA",
  //     condition: "Used",
  //     progress: "In-Progress",
  //   },
  //   {
  //     id: 5,
  //     filename: "product-scan.xls",
  //     linecount: 18,
  //     date: "20-12-2023",
  //     idtype: "ASIN",
  //     marketplace: "FR",
  //     condition: "New",
  //     progress: "Completed",
  //   },
  //   {
  //     id: 6,
  //     filename: "product-scan.xls",
  //     linecount: 20,
  //     date: "29-12-2023",
  //     idtype: "ASIN",
  //     marketplace: "UK",
  //     condition: "Used",
  //     progress: "In-Progress",
  //   },
  // ];
  useEffect(() => {
    if (!isData || isData.length === 0) {
      setIsColumns([]);
      setIsRow([]);
    } else {
      const columns = Object.keys(isData[0]).map((column) => {
        const baseColumn = {
          field: column,
          headerName: capitalizeFirstLetter(column),
          // flex: 1,
          width: 180,
        };
        if (column === "fileName") {
          return {
            ...baseColumn,
            headerName: "File Name",
            renderCell: (params: any) => (
              <>
                <Link
                  to={`/product-details/${params.row.productUploadId}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {params.row.fileName}
                </Link>
              </>
            ),
          };
        } else if (column === "numberOfRecords") {
          return {
            ...baseColumn,
            headerName: "Line Count",
            // width: 70,
            renderCell: (params: any) => (
              <div className="table-text">{params.row.numberOfRecords}</div>
            ),
          };
        } else if (column === "processedPercentage") {
          return {
            ...baseColumn,
            headerName: "Status",
            // width: 70,
            renderCell: (params: any) => (
              <div className="table-text">{params.row.processedPercentage}</div>
            ),
          };
        } else {
          return baseColumn;
        }
      });
      const rows = isData.map((item: any, index: any) => ({
        id: index,
        ...item,
      }));
      const updateColumn = columns.filter(
        (column) => column.field !== "productUploadId"
      );
      setIsColumns(updateColumn);
      setIsRow(rows);
    }
  }, [isData]);
  // const columns = [
  //   // {
  //   //   field: "id",
  //   //   headerCheckboxSelection: true,
  //   //   headerCheckboxSelectionProps: {
  //   //     indeterminate:
  //   //       selectionModel.length > 0 && selectionModel.length < rows.length,
  //   //   },
  //   //   sortable: false,
  //   //   sort: false,
  //   //   renderHeader: (params: any) => (
  //   //     <>
  //   //       <Checkbox
  //   //         color="primary"
  //   //         {...params.headerCheckboxProps}
  //   //         onChange={(e) => {
  //   //           setSelectionModel(
  //   //             e.target.checked ? rows.map((row: any) => row.id) : []
  //   //           );
  //   //         }}
  //   //       />
  //   //       <ArrowDropDownIcon
  //   //         style={{ cursor: "pointer" }}
  //   //         onClick={dropdownHandler}
  //   //       />
  //   //     </>
  //   //   ),
  //   //   renderCell: (params: any) => (
  //   //     <Checkbox
  //   //       checked={selectionModel.includes(params.row.id)}
  //   //       onChange={(e) => {
  //   //         const selectedIds = new Set(selectionModel);
  //   //         if (e.target.checked) {
  //   //           selectedIds.add(params.row.id);
  //   //         } else {
  //   //           selectedIds.delete(params.row.id);
  //   //         }
  //   //         setSelectionModel(Array.from(selectedIds));
  //   //       }}
  //   //     />
  //   //   ),
  //   // },
  //   {
  //     field: "filename",
  //     headerName: "Name",
  //     width: 300,
  //     renderCell: (params: any) => (
  //       <Link
  //         to={`/product-details/${params.row.id}`}
  //         style={{ textDecoration: "none", color: "black" }}
  //       >
  //         {params.row.filename}
  //       </Link>
  //     ),
  //   },
  //   { field: "date", headerName: "Date", width: 130 },
  //   { field: "linecount", headerName: "Line Count", width: 130 },
  //   { field: "idtype", headerName: "ID Type", width: 130 },
  //   {
  //     field: "marketplace",
  //     headerName: "Marketplace",
  //     width: 130,
  //     renderCell: (params: any) => (
  //       <div>
  //         {params.row.marketplace === "CA" ? (
  //           <img
  //             src="https://cdn-icons-png.flaticon.com/512/197/197430.png"
  //             alt="US Flag"
  //             className="flag-icons"
  //           />
  //         ) : params.row.marketplace === "US" ? (
  //           <img
  //             src="https://cdn-icons-png.flaticon.com/512/323/323310.png"
  //             alt="US Flag"
  //             className="flag-icons"
  //           />
  //         ) : params.row.marketplace === "UK" ? (
  //           <img
  //             src="https://cdn-icons-png.flaticon.com/512/197/197374.png"
  //             alt="US Flag"
  //             className="flag-icons"
  //           />
  //         ) : params.row.marketplace === "FR" ? (
  //           <img
  //             src="https://cdn-icons-png.flaticon.com/512/197/197560.png"
  //             alt="US Flag"
  //             className="flag-icons"
  //           />
  //         ) : null}
  //       </div>
  //     ),
  //   },
  //   { field: "condition", headerName: "Condition", width: 191.5 },
  //   {
  //     field: "progress",
  //     headerName: "Progress",
  //     width: 191.5,
  //     renderCell: (params: any) => (
  //       <div>
  //         {params.row.progress === "Completed" ? (
  //           <FontAwesomeIcon
  //             icon={faCircle}
  //             style={{ color: "green", width: "10px" }}
  //           />
  //         ) : params.row.progress === "In-Progress" ? (
  //           <FontAwesomeIcon
  //             icon={faCircle}
  //             style={{ color: "blue", width: "10px" }}
  //           />
  //         ) : null}
  //       </div>
  //     ),
  //   },
  // ];

  const handleCellClick = (params: any) => {
    navigate(`/product-details/${params.row.id}/${params.row.filename}`);
  };

  return (
    <>
      <Verify />
      <Container fluid>
        <Row>
          <Col className="scans-col">
            <div className="scans-header">
              <div className="scan-header">Scans</div>
              <div className="d-flex align-items-end">
                {/* <div>
                  <FormControlLabel
                    control={<Switch />}
                    label={label}
                    // onChange={showArchiveHandler}
                  />
                </div> */}
                <Box
                  component="form"
                  // sx={{
                  //   "& > :not(style)": { m: 1, width: "15inch" },
                  // }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="standard-adornment-password">
                      Search
                    </InputLabel>
                    <Input
                      id="standard-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </div>
            </div>
            {isLoading ? (
              <div className="loading-loader">
                <Loader />
              </div>
            ) : (
              <div
                style={{ height: "400px", width: "100%", position: "relative" }}
              >
                <DataGrid
                  className="scan-table"
                  rows={isRow}
                  columns={isColumns}
                  hideFooter={true}
                  getRowId={(row: any) => row.id}
                  checkboxSelection={false}
                  // onCellClick={handleCellClick}

                  onRowSelectionModelChange={(data: any) => {
                    // setIsDataId(data);
                    // setIsArchive(data);
                    // setSelectedRowCount(data.length);
                  }}
                />
                <Stack spacing={2} className="mt-2 mb-5">
                  <Pagination variant="outlined" shape="rounded" />
                </Stack>

                <div
                  className="dropdown-icon"
                  ref={dropdownRef}
                  style={{ cursor: "pointer" }}
                >
                  {isDropdown && (
                    <div className="dropdown-content">
                      <List>
                        <ListItem button>
                          <ListItemText
                            primary={
                              !isArchiveShow
                                ? "Archive Scans"
                                : "unArchive Scans"
                            }
                            className="dropdown-text"
                          />
                        </ListItem>
                      </List>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Scan;
