import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import graph from "../../Assests/download (1).png";
import IosShareIcon from "@mui/icons-material/IosShare";
import LogoutIcon from "@mui/icons-material/Logout";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./index.css";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const MyScan: React.FunctionComponent = () => {
  const [value, setValue] = useState<any>(0);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const params = useParams();
  const back = () => {
    // navigate("/scans");
    window.history.back();
  };
  const columns = [
    { field: "UPC", headerName: "Price", width: 175 },
    { field: "Cost", headerName: "Conditon", width: 175 },
    { field: "Discount", headerName: "Fulfillment", width: 175 },
    { field: "ASIN", headerName: "Seller", width: 175 },
    { field: "Brand", headerName: "Rating", width: 175 },
    { field: "Reviews", headerName: "Feedback", width: 175 },
    { field: "Rating", headerName: "Stock", width: 175 },
  ];

  const rows = [
    {
      id: 1,
      UPC: "$14.99",
      Cost: "New",
      Discount: "MFN",
      ASIN: "Amazon.com",
      Brand: "96%",
      Reviews: "53,011",
      Rating: "",
    },
    {
      id: 2,
      UPC: "$14.99",
      Cost: "New",
      Discount: "MFN",
      ASIN: "Amazon.com",
      Brand: "96%",
      Reviews: "53,011",
      Rating: "",
    },
    {
      id: 3,
      UPC: "$14.99",
      Cost: "New",
      Discount: "MFN",
      ASIN: "Amazon.com",
      Brand: "96%",
      Reviews: "53,011",
      Rating: "",
    },
    {
      id: 4,
      UPC: "$14.99",
      Cost: "New",
      Discount: "MFN",
      ASIN: "Amazon.com",
      Brand: "96%",
      Reviews: "53,011",
      Rating: "",
    },
    {
      id: 5,
      UPC: "$14.99",
      Cost: "New",
      Discount: "MFN",
      ASIN: "Amazon.com",
      Brand: "96%",
      Reviews: "53,011",
      Rating: "",
    },
    {
      id: 6,
      UPC: "$14.99",
      Cost: "New",
      Discount: "MFN",
      ASIN: "Amazon.com",
      Brand: "96%",
      Reviews: "53,011",
      Rating: "",
    },
  ];
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="back">
            <div>
              <ArrowBackIosIcon fontSize="small" />
            </div>
            <div
              className="back-scan pt-1"
              onClick={back}
              style={{ cursor: "pointer" }}
            >
              Back to Scan{" "}
            </div>
          </div>
          <div className="product-describe">
            <div className="d-flex justify-content-between mx-3 align-items-center flex-wrap">
              <div className="d-flex align-items-center flex-wrap">
                <div>
                  <img
                    src="https://m.media-amazon.com/images/W/MEDIAX_792452-T1/images/I/41b+4ptqjhL._SY879_.jpg"
                    alt="img"
                    className="img-fluid"
                    width={100}
                  />
                </div>
                <div className="item-details">
                  <p>Shone: Shone 10kg Dumbbells Set of 2 Set for Home Gym</p>{" "}
                  <p>ASIN: B08L3N1QWQ</p> <p>Buy Box Price: ₹2,449.00</p>{" "}
                  <p>Last Price Changed: 08-12-2023,11:49am</p>{" "}
                  <p>Condition: New</p>
                </div>
              </div>
              <div className="item-details text-end">
                <div className="d-flex align-items-center justify-content-end me-3">
                  <IosShareIcon fontSize="small" className="icons-items" />
                  <LogoutIcon fontSize="small" className="icons-items" />
                  <HelpOutlineIcon fontSize="small" className="icons-items" />
                </div>
                <p>Sales Rank: 8,2023</p> <p>30 Day Average: 28,449</p>{" "}
                <p>90 Day Average: 31,227</p> <p>180 Day Average: 30,157</p>{" "}
                <p>Estimated Monthly Sales: 50,879</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="price-rank-history">
            <div className="price-rank">
              <p className="m-0 p-0">Price & Rank History</p>
            </div>
            <div>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="30 Days" {...a11yProps(0)} />
                    <Tab label="90 Days" {...a11yProps(1)} />
                    <Tab label="365 Days" {...a11yProps(2)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <div className="d-flex justify-content-center">
                    <img src={graph} alt="graph" className="graph img-fluid" />
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <div className="d-flex justify-content-center">
                    <img src={graph} alt="graph" className="graph img-fluid" />
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <div className="d-flex justify-content-center">
                    <img src={graph} alt="graph" className="graph img-fluid" />
                  </div>
                </CustomTabPanel>
              </Box>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="offer-table">
            <div className="offer">
              <p className="m-0 p-0">Offers</p>
            </div>
            <div style={{ height: "400px", width: "100%" }}>
              <DataGrid
                className="scan-table"
                rows={rows}
                columns={columns}
                hideFooter={true}
                getRowId={(row) => row.id}
                checkboxSelection={false}
                // onCellClick={handleCellClick}
                onRowSelectionModelChange={(data: any) => {
                  //   setIsDataId(data);
                  //   setSelectedRowCount(data.length);
                }}
              />
              <Stack spacing={2} className="mt-2 mb-5">
                <Pagination variant="outlined" shape="rounded" />
              </Stack>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <div className="profit-table">
            <div className="profit">
              <p className="m-0 p-0">Profit</p>
            </div>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="varition-table">
            <div className="varition">
              <p className="m-0 p-0">Variations</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default MyScan;
