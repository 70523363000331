import axios from "axios";
// const baseUrl = "https://api.amznitro.com";
const baseUrl = "https://44.221.254.226";
// const baseUrl = "http://localhost:5278";
let authorization: string;

const accessToken = localStorage.getItem("accessToken");
authorization = "Bearer " + accessToken;

export const token = () => {
  const accessToken = localStorage.getItem("accessToken");
  authorization = "Bearer " + accessToken;
};

export const login = (data: any) => {
  const { email, password } = data;
  return axios.post(`${baseUrl}/Account/Login`, {
    email,
    password,
  });
};
export const registeration = (data: any) => {
  const { userName, email, password } = data;
  return axios.post(`${baseUrl}/Account/Register`, {
    userName,
    email,
    password,
  });
};
export const logout = () => {
  return axios.post(`${baseUrl}/Account/Logout`, null, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const uploadFile = (file: any) => {
  return axios.post(`${baseUrl}/ProductUpload/UploadFile`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: authorization,
    },
  });
};
export const productUpload = (request: any) => {
  return axios.post(
    `${baseUrl}/ProductUpload/UploadList`,
    { request },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization,
      },
    }
  );
};
export const addProductUpload = (request: any) => {
  return axios.post(`${baseUrl}/ProductUpload/AddProductUpload`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const productDetails = (productId: any, request: any) => {
  return axios.post(`${baseUrl}/ProductUpload/${productId}/Products`, request, {
    headers: {
      "Content-Type": "application/json",
      Authorization: authorization,
    },
  });
};
export const uploadProcess = () => {
  return axios.post(`${baseUrl}/ProductUpload/UploadProcess`);
};
