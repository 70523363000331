import React from "react";
import logo from "./logo.svg";
import Main from "./Layout/main";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Components/Dashboard";
import Setting from "./Components/Setting";
import ProductFolder from "./Components/ProductsFolder";
import Scan from "./Components/Scan";
import ParticularItem from "./Components/ParticularItem";
import MyScan from "./Components/ScanProduct";
import Login from "./UserLoginFlow/login";
import ResetPassword from "./UserLoginFlow/resetPassword";
import Register from "./UserLoginFlow/register";
const accessToken = localStorage.getItem("accessToken");
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="signup" element={<Register />} />
        <Route path="/forgot-password" element={<ResetPassword />} />
        <Route path="/" element={<Login />} />
        <Route element={<Main />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/product-folder" element={<ProductFolder />} />
          <Route path="/scans" element={<Scan />} />
          <Route
            path="/product-details/:id"
            element={<ParticularItem />}
          />
          <Route path="/my-scan/:id" element={<MyScan />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
