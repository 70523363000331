import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Verify from "../EmailVerfiy";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
const ProductFolder: React.FunctionComponent = () => {
  const [isInput, setIsInput] = useState<any>(true);
  //   const label = "Show Archived";
  const columns = [
    { field: "filename", headerName: "Folder Name", width: 700 },
    { field: "Date", headerName: "Date", width: 500 },
    // { field: "filename", headerName: "File name", width: 300 },
    // { field: "date", headerName: "Date", width: 130 },
    // { field: "linecount", headerName: "Line Count", width: 130 },
    // { field: "idtype", headerName: "ID Type", width: 130 },
    // {
    //   field: "marketplace",
    //   headerName: "Marketplace",
    //   width: 130,
    //   renderCell: (params: any) => (
    //     <div>
    //       {params.row.marketplace === "CA" ? (
    //         <img
    //           src="https://cdn-icons-png.flaticon.com/512/197/197430.png"
    //           alt="US Flag"
    //           className="flag-icons"
    //         />
    //       ) : params.row.marketplace === "US" ? (
    //         <img
    //           src="https://cdn-icons-png.flaticon.com/512/323/323310.png"
    //           alt="US Flag"
    //           className="flag-icons"
    //         />
    //       ) : params.row.marketplace === "UK" ? (
    //         <img
    //           src="https://cdn-icons-png.flaticon.com/512/197/197374.png"
    //           alt="US Flag"
    //           className="flag-icons"
    //         />
    //       ) : params.row.marketplace === "FR" ? (
    //         <img
    //           src="https://cdn-icons-png.flaticon.com/512/197/197560.png"
    //           alt="US Flag"
    //           className="flag-icons"
    //         />
    //       ) : null}
    //     </div>
    //   ),
    // },
    // { field: "condition", headerName: "Condition", width: 191.5 },
    // {
    //   field: "progress",
    //   headerName: "Progress",
    //   width: 191.5,
    //   renderCell: (params: any) => (
    //     <div>
    //       {params.row.progress === "Completed" ? (
    //         <FontAwesomeIcon
    //           icon={faCircle}
    //           style={{ color: "green", width: "10px" }}
    //         />
    //       ) : params.row.progress === "In-Progress" ? (
    //         <FontAwesomeIcon
    //           icon={faCircle}
    //           style={{ color: "blue", width: "10px" }}
    //         />
    //       ) : null}
    //     </div>
    //   ),
    // },
  ];

  const rows = [
    {
      id: 1,
      filename: "product-scan.xls",
      linecount: 5,
      date: "04-12-2023",
      idtype: "ASIN",
      marketplace: "CA",
      condition: "New",
      progress: "Completed",
    },
    {
      id: 2,
      filename: "product-scan.csv",
      linecount: 8,
      date: "05-12-2023",
      idtype: "UPC",
      marketplace: "US",
      condition: "Used",
      progress: "In-Progress",
    },
  ];

  return (
    <>
      <Verify />
      <Container fluid>
        <Row>
          <Col className="scans-col">
            <div className="product-header">
              <div className="scan-header">Product Folders</div>
              <div className="d-flex align-items-end">
                <Box
                  component="form"
                  // sx={{
                  //   "& > :not(style)": { m: 1, width: "25inch" },
                  // }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">
                      Search
                    </InputLabel>
                    <Input
                      id="standard-adornment-password"
                      endAdornment={
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Box>
              </div>
            </div>
            <div style={{ height: "56px", width: "100%" }}>
              <DataGrid
                className="scan-table"
                rows={rows}
                columns={columns}
                hideFooter={true}
                getRowId={(row) => row.id}
                checkboxSelection={false}
                onRowSelectionModelChange={(data: any) => {
                  //   setIsDataId(data);
                  //   setSelectedRowCount(data.length);
                }}
              />
              {/* <Stack spacing={2} className="mt-2 mb-5">
                <Pagination variant="outlined" shape="rounded" />
              </Stack> */}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProductFolder;
