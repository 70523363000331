import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./index.css";
import { productDetails } from "../../Service/services";

const ParticularItem: React.FunctionComponent = () => {
  const [isPerPage, setIsPerPage] = useState<any>(10);
  const [isCurrentPage, setIsCurrentPage] = useState<any>(1);
  const [isData, setData] = useState<any>([]);
  const [isresponse, setIsResponse] = useState<any>([]);
  const [isRow, setIsRow] = useState<any>([]);
  const [isColumns, setIsColumns] = useState<any>([]);
  const [isTableLoading, setIsTableLoading] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<any>(true);
  const params = useParams();
  const navigate = useNavigate();
  const handleCellClick = (params: any) => {
    navigate(`/my-scan/${params.row.id}`);
  };

  useEffect(() => {
    getProductDetails();
  }, [isCurrentPage]);
  const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const getProductDetails = async () => {
    const request = {
      page: isCurrentPage,
      perPage: isPerPage,
    };
    try {
      const response = await productDetails(params.id, request);
      if (response.status === 200) {
        setIsResponse(response.data);
        setData(response.data.data);
        setIsLoading(false);
        setIsTableLoading(false);
      }
    } catch (error: any) {
      // if (error.response.data.ErrorMessage === "Invalid token") {
      //   localStorage.removeItem("accessToken");
      //   localStorage.removeItem("userProfile");
      //   navigate("/");
      // }
    }
  };
  const handlePageChange = (event: any, value: any) => {
    setIsCurrentPage(value);
  };
  useEffect(() => {
    if (!isData || isData.length === 0) {
      setIsColumns([]);
      setIsRow([]);
    } else {
      const columns = Object.keys(isData[0]).map((column) => {
        const baseColumn = {
          field: column,
          headerName: capitalizeFirstLetter(column),
          // flex: 1,
          width: 180,
        };
        if (column === "fileName") {
          return {
            ...baseColumn,
            headerName: "File Name",
            renderCell: (params: any) => (
              <div className="table-text">{params.row.fileName}</div>
            ),
          };
        } else if (column === "numberOfRecords") {
          return {
            ...baseColumn,
            headerName: "Line Count",
            // width: 70,
            renderCell: (params: any) => (
              <div className="table-text">{params.row.numberOfRecords}</div>
            ),
          };
        } else if (column === "processedPercentage") {
          return {
            ...baseColumn,
            headerName: "Status",
            // width: 70,
            renderCell: (params: any) => (
              <div className="table-text">{params.row.processedPercentage}</div>
            ),
          };
        } else {
          return baseColumn;
        }
      });
      const rows = isData.map((item: any, index: any) => ({
        id: index,
        ...item,
      }));
      const updateColumn = columns.filter(
        (column) => column.field !== "productUploadId"
      );
      setIsColumns(updateColumn);
      setIsRow(rows);
    }
  }, [isData]);
  // const columns = [
  //   { field: "UPC", headerName: "UPC", width: 100 },
  //   { field: "Cost", headerName: "Cost", width: 100 },
  //   { field: "Discount", headerName: "Discount Premium", width: 100 },
  //   { field: "ASIN", headerName: "ASIN", width: 100 },
  //   {
  //     field: "Title",
  //     headerName: "Title",
  //     width: 150,
  //     renderCell: (params: any) => (
  //       <Link
  //         to={`/my-scan/${params.row.id}`}
  //         style={{ textDecoration: "none", color: "black" }}
  //       >
  //         {params.row.Title}
  //       </Link>
  //     ),
  //   },
  //   { field: "Brand", headerName: "Brand", width: 100 },
  //   { field: "Reviews", headerName: "Reviews", width: 100 },
  //   { field: "Rating", headerName: "Rating", width: 100 },
  //   { field: "Sales", headerName: "Sales Rank", width: 100 },
  //   { field: "Avg", headerName: "Avg 30 Days", width: 100 },
  // ];

  // const rows = [
  //   {
  //     id: 1,
  //     UPC: 12345678,
  //     Cost: "$5.00",
  //     Discount: "$0.00",
  //     ASIN: 12345678,
  //     Title: "product-scan.xls",
  //     Brand: "Amazon",
  //     Reviews: "2,691",
  //     Rating: 12345,
  //     Sales: "3,848",
  //     Avg: "",
  //   },
  //   {
  //     id: 2,
  //     UPC: 12345678,
  //     Cost: "$5.00",
  //     Discount: "$0.00",
  //     ASIN: 12345678,
  //     Title: "product-scan.xls",
  //     Brand: "Amazon",
  //     Reviews: "2,691",
  //     Rating: 12345,
  //     Sales: "3,848",
  //     Avg: "",
  //   },
  //   {
  //     id: 3,
  //     UPC: 12345678,
  //     Cost: "$5.00",
  //     Discount: "$0.00",
  //     ASIN: 12345678,
  //     Title: "product-scan.xls",
  //     Brand: "Amazon",
  //     Reviews: "2,691",
  //     Rating: 12345,
  //     Sales: "3,848",
  //     Avg: "",
  //   },
  //   {
  //     id: 4,
  //     UPC: 12345678,
  //     Cost: "$5.00",
  //     Discount: "$0.00",
  //     ASIN: 12345678,
  //     Title: "product-scan.xls",
  //     Brand: "Amazon",
  //     Reviews: "2,691",
  //     Rating: 12345,
  //     Sales: "3,848",
  //     Avg: "",
  //   },
  //   {
  //     id: 5,
  //     UPC: 12345678,
  //     Cost: "$5.00",
  //     Discount: "$0.00",
  //     ASIN: 12345678,
  //     Title: "product-scan.xls",
  //     Brand: "Amazon",
  //     Reviews: "2,691",
  //     Rating: 12345,
  //     Sales: "3,848",
  //     Avg: "",
  //   },
  //   {
  //     id: 6,
  //     UPC: 12345678,
  //     Cost: "$5.00",
  //     Discount: "$0.00",
  //     ASIN: 12345678,
  //     Title: "product-scan.xls",
  //     Brand: "Amazon",
  //     Reviews: "2,691",
  //     Rating: 12345,
  //     Sales: "3,848",
  //     Avg: "",
  //   },
  // ];

  return (
    <Container fluid>
      <Row>
        <Col className="scans-col">
          <div className="scan-header mx-2">{params.file}</div>
          <div style={{ height: "600px", width: "100%" }}>
            <DataGrid
              className="scan-table"
              rows={isRow}
              columns={isColumns}
              hideFooter={true}
              getRowId={(row) => row.id}
              checkboxSelection
              onCellClick={handleCellClick}
              onRowSelectionModelChange={(data: any) => {
                //   setIsDataId(data);
                //   setSelectedRowCount(data.length);
              }}
            />
            <Stack spacing={2} className="mt-2 mb-5">
              <Pagination
                variant="outlined"
                shape="rounded"
                count={isresponse.lastPage}
                page={isCurrentPage}
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ParticularItem;
